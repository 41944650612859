import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import { HeadingBig } from 'components/UI/Headings';

import Photo from 'components/Photo';

import { Container } from './styles';

const PartnersPageTemplate = ({ data, pageContext }) => {
  const { datoCmsPartnersPage: pageData, allDatoCmsPartner: partners } = data;
  const { title, seo, partnersDescription } = pageData;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <StructuredText data={partnersDescription.value} />
          <Container>
            {partners.edges.map((partner, index) => {
              const {
                link,
                info,
                image: { url },
              } = partner.node;

              return (
                <li key={index}>
                  <a
                    href={link || null}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Photo src={url} description={info} />
                  </a>
                </li>
              );
            })}
          </Container>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default PartnersPageTemplate;

export const query = graphql`
  query PartnersPageQuery($locale: String!) {
    datoCmsPartnersPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      partnersDescription {
        value
      }
    }
    allDatoCmsPartner(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          image {
            alt
            blurhash
            width
            url
            height
          }
          info
          link
        }
      }
    }
  }
`;
