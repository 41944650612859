import styled from 'styled-components';

import { media } from 'styles/mixins';

const Container = styled.ul`
  grid-template-columns: repeat(5, 1fr);
  gap: 3rem 2rem;
  display: grid;

  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
  `}

  img {
    margin: 0 auto;
    min-width: auto;
    max-height: 6rem;
    max-width: 12rem;
    object-fit: contain;
  }
`;

export { Container };
